<template>
    <v-container fluid>
        <v-layout class="styleLastMinute" flex-row>
            <h1>Admin Page</h1>
            <h2>
                DEN ÄR SKITFUL JAG VET MEN MAN KAN LÄGGA TILL PRODUKT, JSON DÄR
                NERE UPPDATERAS OCH DEN LÄGGS TILL PÅ PRODUKTSIDAN MED
            </h2>
            <p>
                Info:
                <input v-model="id" placeholder="ID:" />
            </p>
            <p>
                <input v-model="title" placeholder="Title" />
            </p>
            <p>
                <input v-model="price" placeholder="Price" />
            </p>
            <p>
                <input v-model="image" placeholder="Image Link" />
            </p>
            <p>
                <input v-model="description" placeholder="Description" />
            </p>
            <p>
                <input v-model="category" placeholder="Category" />
            </p>
            <p>
                <input v-model="stock" placeholder="Stock" />
            </p>
            <div id="info_list">
                <button
                    @click.prevent="
                        addProduct(
                            'id',
                            'title',
                            'price',
                            'image',
                            'description',
                            'category',
                            'stock'
                        )
                    "
                >
                    Add
                </button>
                <pre>{{ $data | json }}</pre>
            </div>
        </v-layout>
    </v-container>
</template>

<script>
import products from '@/products.json';
export default {
    name: 'Admin',
    el: '#info_list',
    data: function () {
        return {
            products,
            info: {
                id: '',
                title: '',
                price: '',
                image: '',
                description: '',
                category: '',
                stock: '',
            },
        };
    },
    methods: {
        addProduct: function () {
            var object = {
                id: '',
                title: '',
                price: '',
                image: '',
                description: '',
                category: '',
                stock: '',
            };

            object.id = this.id;
            object.title = this.title;
            object.price = this.price;
            object.description = this.description;
            object.category = this.category;
            object.image = this.image;
            object.stock = this.stock;

            console.log('Product added');
            this.products.push(object);
        },
    },
};
</script>

<style scoped>
.styleLastMinute {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>
